<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="90vw">
      <v-card-text>

        <v-card-title v-if="!set_etas">Purchase Order Confirmation</v-card-title>
        <v-card-title v-if="set_etas">Please provide the ETA for the items below.</v-card-title>
        <v-card-text v-if="set_etas">


          <v-row>
            <v-col cols="6">
              <h3>All ETAs have been defaulted to {{vendor.default_eta_days}} days from the PO Date.</h3>
              <div>PO: {{po}}</div>
              <div>PO Date: {{db.purchase_orders[po].po_date}}</div>
            </v-col>
            <v-col cols="6">
              <div style="float:right">
                <span style="font-weight:bold;font-size:12px">Set All ETAs Below To:</span>
                <table>
                  <td>
                    <div>
                      <datepicker v-model="all_etas" format="M/dd/yyyy" name="index"></datepicker>
                    </div>
                  </td>
                  <td><div style="padding:0px 5px">or</div></td>
                  <td>
                    <v-btn size="small" style="background:#ff7214;color:white;width:188px" @click="setAllToUTP()">UNABLE TO PROVIDE ETA</v-btn>
                  </td>
                  <td v-if="user !== 'vendor'"><div style="padding:0px 5px">or</div></td>
                  <td v-if="user !== 'vendor'">
                    <v-btn size="small" style="background:#ff7214;color:white;width:188px" @click="setAllToTBD()">ETA TBD</v-btn>
                  </td>                  
                </table>
              </div>
            </v-col>
          </v-row>

        </v-card-text>
        <v-table :height="$vuetify.display.height - 275 + 'px'" fixed-header style="border:1px solid lightgray; margin:0px 15px;">
          <thead>
            <tr>
              <th>Mfg Product Number</th>
              <th>Vendor Product Number</th>
              <th>Product Description</th>
              <th>Qty Not Yet Received</th>
              <th>Shared Note</th>
              <th v-if="set_etas">ETA</th>
              <th v-if="set_etas"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in po_items">
              <td>{{item.manufacturer_part_number}}</td>

              <td>{{item.vendor_part_number}}</td>

              <td>{{item.product_description}}</td>

              <td>{{item.qty_open + ' of ' + item.po_quantity}}</td>

              <td v-if="set_etas">
                <v-text-field variant="outlined" density="compact" placeholder="Type note here..." v-model="item_notes[item.item_key]" style="min-width:300px;position:relative;top:10px"></v-text-field>
              </td>

              <td v-if="!set_etas">
                <div v-for="n in item.shared_notes" style="border-bottom:1px dashed;margin-top:5px;margin-bottom:5px;line-height:100%">
                  <div style="font-style:italic">{{n.note}}</div>
                  <div style="font-size:8px;line-height:100%">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                </div>
                <div style="margin:5px 0px"><v-btn size="x-small" style="background:#ff7214;color:white" @click="addNote('shared',po, item.item_key)">Add Note</v-btn></div>
              </td>

              <td v-if="set_etas">
                <div>
                  <datepicker v-if="po_items[index].eta !== 'UTP' && po_items[index].eta !== 'TBD'" v-model="po_items[index].eta" format="M/dd/yyyy" name="index"></datepicker>
                  <div style="font-size:12px" v-if="po_items[index].eta === 'UTP'">UNABLE TO PROVIDE ETA</div>
                  <div style="font-size:12px" v-if="po_items[index].eta === 'TBD'">ETA TBD</div>
                </div>
              </td>
              <td v-if="set_etas">
                <v-btn size="small" v-if="po_items[index].eta !== 'UTP' && po_items[index].eta !== 'TBD'" style="background:#ff7214;color:white;width:188px;margin-top:5px;" @click="unableToProvideETA(po_items[index].item_key)">UNABLE TO PROVIDE ETA</v-btn>
                <v-btn size="small" v-if="po_items[index].eta !== 'UTP' && po_items[index].eta !== 'TBD' && user !== 'vendor'" style="background:#ff7214;color:white;width:188px;margin-top:5px;margin-bottom:5px" @click="tbdETA(po_items[index].item_key)">ETA TBD</v-btn>
                <v-btn size="small" v-if="po_items[index].eta === 'UTP' || po_items[index].eta === 'TBD'" style="background:#ff7214;color:white;width:186px" @click="ableToProvideETA(po_items[index].item_key)">SET ETA</v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text>
      <v-card-actions v-if="!set_etas">
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-spacer></v-spacer>
        <v-card-title v-if="!set_etas">Are all items on PO <i>{{po}}</i> expected to arrive by {{getEtaDateEstimate}}?</v-card-title>
        <v-btn style="background:green;color:white" @click="confirmPO()">YES</v-btn>
        <v-btn style="background:red;color:white" @click="setETAs()">NO</v-btn>
      </v-card-actions>
      <v-card-actions v-if="set_etas">
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="cancelSetETAs()">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="initsSaveETAs()">SAVE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <NoteItemPopUp v-if="note_popup" @close="closeNotePopUp()" :po="po_for_note" :itemkey='item_key_for_note' :type="note_type" :isvendor="true"/>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Datepicker from 'vuejs3-datepicker';
import NoteItemPopUp from '@/components/NoteItemPopUp.vue'
import { emailMixin } from '../mixins/emailMixin.js'

export default {
  name: 'VendorPurchaseOrderConfirmation7DayPopUp',
  components: {Datepicker,NoteItemPopUp},
  mixins: [emailMixin],
  emits: ["close"],
  props:{
    po: String,
    vendor: Object
  },
  data(){
    return{
      dialog: true,
      set_etas: false,
      po_items: [],
      note_type: null,
      note_popup: false,
      po_for_note: null,
      item_key_for_note: null,
      all_etas: '0/0/00',
      saving: false,
      item_notes: {

      }
    }
  },
  beforeMount(){
    var self = this
    var po_date = self.db.purchase_orders[self.po].po_date

    firebase.database().ref('purchase_orders').child(self.po).on('value', function(po_snapshot) {
      if(!self.saving){
        var purchase_order = po_snapshot.val()
        self.po_items = []
        for(var item_key in purchase_order.items){
          var item = JSON.parse(JSON.stringify(purchase_order.items[item_key]))
          item.item_key = item_key
          item.eta = (item.eta && item.eta !== 'UTP' && item.eta !== 'UTP') ? item.eta : ((item.eta !== 'UTP' && item.eta !== 'TBD') ? Moment(po_date).add('days', self.vendor.default_eta_days).format('M/DD/YY') : item.eta)
          if(item.line_complete !== 'Y'){
            self.po_items.push(item)
          }
          if(!self.item_notes[item_key]){
            self.item_notes[item_key] = ''
          }
        }
      }
    })
  },
  watch: {
    all_etas(val){
      if(val !== 'UTP' && val !== 'TBD' && val !== '0/0/00'){
        for(var i = 0; i < this.po_items.length; i++){
          this.po_items[i].eta = val
        }
      }
    },
    po_items:{
      handler: function (val, oldVal) {
        for(var i = 0; i < this.po_items.length;i++){
          if(this.all_etas !== '0/0/00' && this.all_etas !== this.po_items[i].eta){
            this.all_etas = '0/0/00'
          }
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['user','db']),
    getEtaDateEstimate(){
      return Moment(this.db.purchase_orders[this.po].po_date).add('days', this.vendor.default_eta_days).format('M/DD/YY')
    },
  },
  methods:{
    addNote(type,po,item_key){
      this.po_for_note = po
      this.item_key_for_note = item_key
      this.note_type = type
      this.note_popup = true
    },
    closeNotePopUp(){
      this.note_popup = false
      this.po_for_note = null
      this.item_key_for_note = null
    },
    confirmPO(){
      var self = this
      var po_date = self.db.purchase_orders[self.po].po_date
      var item_default_eta_date = Moment(po_date).add('days', self.vendor.default_eta_days).format('M/DD/YY')
      var eta_set_timestamp = Date.now()
      firebase.database().ref('purchase_orders').child(self.po + '/action_vendor_po_conf_response').set('Confirmed')

      firebase.database().ref('purchase_orders').child(self.po + '/vendor_po_conf_user').set(self.user)

      firebase.database().ref('purchase_orders').child(self.po + '/vendor_po_conf_date').set(Date.now())

      for(var i = 0; i < self.po_items.length; i++){
        var item_key = self.po_items[i].item_key
        firebase.database().ref('purchase_orders').child(self.po).child('items').child(item_key).child('eta').set(item_default_eta_date)
        firebase.database().ref('purchase_orders').child(self.po).child('items').child(item_key).child('eta_last_provided').set(eta_set_timestamp)
        firebase.database().ref('purchase_orders').child(self.po).child('items').child(item_key).child('eta_history').push( {'eta': item_default_eta_date, 'date_provided': eta_set_timestamp, 'approx_eta_set': true} )
        firebase.database().ref('purchase_orders').child(self.po).child('items').child(item_key).child('approx_eta_set').set(true)
      }

      self.$emit('close')
    },
    setAllToUTP(){
      for(var i = 0; i < this.po_items.length; i++){
        this.po_items[i].eta = 'UTP'
      }
      this.all_etas = 'UTP'
    },
    setAllToTBD(){
      for(var i = 0; i < this.po_items.length; i++){
        this.po_items[i].eta = 'TBD'
      }
      this.all_etas = 'TBD'
    },
    unableToProvideETA(item_key){
      for(var i = 0; i < this.po_items.length; i++){
        if(this.po_items[i].item_key === item_key){
          this.po_items[i].eta = 'UTP'
        }
      }
      this.all_etas = '0/0/00'
    },
    tbdETA(item_key){
      for(var i = 0; i < this.po_items.length; i++){
        if(this.po_items[i].item_key === item_key){
          this.po_items[i].eta = 'TBD'
        }
      }
      this.all_etas = '0/0/00'
    },
    ableToProvideETA(item_key){
      var po_date = this.db.purchase_orders[this.po].po_date
      for(var i = 0; i < this.po_items.length; i++){
        if(this.po_items[i].item_key === item_key){
          this.po_items[i].eta = Moment(po_date).add('days', this.vendor.default_eta_days).format('M/DD/YY')
        }
      }
    },
    setETAs(){
      this.set_etas = true
    },
    cancelSetETAs(){

      this.set_etas = false
    },
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    sendEmailForUTP(email){
      this.itemUTPEmail(email)
    },
    initsSaveETAs(){
      var self = this
      var eta_set_timestamp = Date.now()
      var missing_utp_note = false
      for(var i = 0; i < self.po_items.length; i++){
        var item_key = self.po_items[i].item_key
        if(self.po_items[i].eta === 'UTP'){
          if(self.item_notes[item_key].trim() === ''){
            missing_utp_note = true
          }
        }
      }

      if(missing_utp_note){
        alert('For each line item that you are unable to provide an ETA, please explain why in the shared note field.')
        return
      }

      self.saving = true

      for(var i = 0; i < this.po_items.length; i++){
        var eta = (self.po_items[i].eta !== 'UTP' && self.po_items[i].eta !== 'TBD') ? Moment(self.po_items[i].eta).format('M/DD/YY') : self.po_items[i].eta

        if(eta === 'UTP'){
          var email = {
            po: self.po,
            item: self.po_items[i].product,
            vendor: self.vendor.name,
            note: JSON.stringify(self.item_notes[self.po_items[i].item_key]).replace(/\\n/g, '<br>')
          }
          self.sendEmailForUTP(email)
        }
        self.completeSaveETAs(self.po_items[i].item_key,eta,eta_set_timestamp)
      }
      firebase.database().ref('purchase_orders').child(self.po + '/action_vendor_po_conf_response').set('Confirmed')
      firebase.database().ref('purchase_orders').child(self.po + '/vendor_po_conf_user').set(self.user)
      firebase.database().ref('purchase_orders').child(self.po + '/vendor_po_conf_date').set(Date.now())
      self.$emit('close')
    },
    completeSaveETAs(item_key,eta,timestamp){
      var self = this
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(item_key).child('eta').set(eta)
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(item_key).child('eta_last_provided').set(timestamp)
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(item_key).child('eta_escalation_requests_cnt').set(0)
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(item_key).child('eta_history').push( {'eta': eta, 'date_provided': timestamp} )
      firebase.database().ref('purchase_orders').child(self.po).child('items').child(item_key).child('approx_eta_set').set(false)
      if(item_key.trim() !== ''){
        firebase.database().ref('purchase_orders').child(self.po).child('items').child(item_key).child('shared_notes').push({note: self.item_notes[item_key], submitted_by: 'vendor', submitted_date: Date.now()})
      }
    }
  }
}
</script>

<style>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
  .vuejs3-datepicker__icon{
    padding-top: 2px;
  }
  .vuejs3-datepicker__value{
    padding: 5px 15px;
  }
  .vuejs3-datepicker__calendar-topbar{
    display: none;
  }
</style>


