import { createStore } from 'vuex'
import firebase from 'firebase/compat/app';
import Moment from 'moment'
import router from '../router'

export default createStore({
  state: {
    version: 'V 37.2.0',
    user: null,
    isLoading: false,
    db: {
      customers: {},
      backorders: {},
      recently_completed_backorders: {},
      users: {},
      purchase_orders: {},
      vendors: {},
      accounts_receivable: {},
      analytics: {},
      vendor_page_mapping: {},
      last_purchase_order_file_upload: null,
      last_vendor_file_upload: null,
      last_customer_file_upload: null,
      last_backorder_file_upload: null,
      last_ar_file_upload: null,
      last_purchase_order_file_pull: null,
      last_vendor_file_pull: null,
      last_customer_file_pull: null,
      last_backorder_file_pull: null,
      last_po_confirmations_and_eta_requests_triggered: null,
      shipping_companies: []
    },
    selectedSpecialist: 'All',
    selectedExecutive: 'All',
  },
  getters: {
    user: (state) => state.user,
    db: (state) => state.db,
    isLoading: (state) => state.isLoading,
    selectedSpecialist: (state) => state.selectedSpecialist,
    selectedExecutive: (state) => state.selectedExecutive,

    // - allow 'mark as confirmed' if po is Disputed, Pending, Not Requested
    // - allow 'mark as disputed' if po is Confirmed, Pending, Not Requested

    po_confirmation_status: (state) => (po) => {
      // action_po_complete ('Y','N')
      // action_vendor_po_conf_response ('Not Requested', 'Pending', 'Disputed', 'Confirmed')

      if(po.action_po_complete === 'Y'){
        return 'Complete' // If PO is missing from most recent import
      }

      if(po.action_vendor_po_conf_response === 'Confirmed'){
        return 'Confirmed'   // vendor or internal user 'confirms' receipt of the PO
      }

      if(po.action_vendor_po_conf_response === 'Disputed'){
        return 'Disputed' // vendor clicks "dispute" for PO instead of Confirm or internal user marks 'dispute' for PO
      }

      if(po.action_vendor_po_conf_response === 'Pending'){
        return 'Pending' // internal user or automated script sends the vendor an email asking to confirm
      }

      if(po.action_vendor_po_conf_response === 'Not Requested'){
        return 'Not Requested'  // PO first uploaded, no internal user triggered confirmation email or no automated script sent email asking vendor to confirm yet
      }

      return 'Error In Logic'
    },
    item_eta_status: (state, getters) => (po, item_key) => {
      var line_item = po.items[item_key]
      var po_confirmation_status = getters.po_confirmation_status(po)
      var line_item_eta_date = Moment(line_item.eta, 'M/D/YY').startOf('day')
      var today_date = Moment().startOf('day')

      if(po_confirmation_status === 'Complete' || line_item.line_complete === 'Y'){
        return 'Complete'
      }

      if(po_confirmation_status === 'Not Requested'){
        return 'Not Requested'
      }

      if(po_confirmation_status === 'Pending'){
        return 'PO Conf Pending'
      }

      if(po_confirmation_status === 'Disputed'){
        return 'PO Disputed'
      }

      if(line_item.eta === 'UTP'){
        return 'Unable To Provide'
      }

      if(line_item.eta === 'TBD'){
        return 'TBD'
      }

      if(line_item_eta_date >= today_date){
        return 'On Track'
      }

      if(line_item_eta_date < today_date){
        return 'Past Due'
      }

      return 'Error In Logic'
    }
  },
  mutations: {
    loadDatabase(state, uid) {
      var self = this

      state.user = uid

      firebase.database().ref('customers').on('value', function(snapshot) {
        state.db.customers = snapshot.val();
      });

      firebase.database().ref('backorders').orderByChild('completed').equalTo(false).on('value', function(snapshot) {
        state.db.backorders = snapshot.val();
      });

      var now = Date.now(); // Get the current timestamp in milliseconds
      var sevenDaysAgo = now - (7 * 24 * 60 * 60 * 1000); // Calculate the timestamp for 7 days ago in milliseconds
      
      firebase.database().ref('completed_backorders').orderByChild('backorder_marked_complete').startAt(sevenDaysAgo).on('value', function(snapshot) {
        state.db.recently_completed_backorders = snapshot.val();
      });

      firebase.database().ref('users').on('value', function(snapshot) {
        state.db.users = snapshot.val();
      });

      firebase.database().ref('users').child(state.user).on('value', function(snapshot) {
        var u = snapshot.val()
        if(u && u.disabled){
          console.log('log me out!')
          firebase.auth().signOut().then(function() { // force user logout if deactivated.
            router.push('/')
          })
        }
      })

      firebase.database().ref('purchase_orders').orderByChild('action_po_complete').equalTo('N').on('value', function(snapshot) {
        state.db.purchase_orders = snapshot.val();
      });

      firebase.database().ref('vendors').on('value', function(snapshot) {
        state.db.vendors = snapshot.val();
      });

      firebase.database().ref('accounts_receivable').on('value', function(snapshot) {
        state.db.accounts_receivable = snapshot.val();
      });

      firebase.database().ref('vendor_page_mapping').on('value', function(snapshot) {
        state.db.vendor_page_mapping = snapshot.val();
      });

      firebase.database().ref('last_purchase_order_file_upload').on('value', function(snapshot) {
        state.db.last_purchase_order_file_upload = snapshot.val();
      });

      firebase.database().ref('last_vendor_file_upload').on('value', function(snapshot) {
        state.db.last_vendor_file_upload = snapshot.val();
      });

      firebase.database().ref('last_customer_file_upload').on('value', function(snapshot) {
        state.db.last_customer_file_upload = snapshot.val();
      });

      firebase.database().ref('last_backorder_file_upload').on('value', function(snapshot) {
        state.db.last_backorder_file_upload = snapshot.val();
      });

      firebase.database().ref('last_ar_file_upload').on('value', function(snapshot) {
        state.db.last_ar_file_upload = snapshot.val();
      });

      firebase.database().ref('last_vendor_file_pull').on('value', function(snapshot) {
        state.db.last_vendor_file_pull = snapshot.val();
      });

      firebase.database().ref('last_backorder_file_pull').on('value', function(snapshot) {
        state.db.last_backorder_file_pull = snapshot.val();
      });

      firebase.database().ref('last_customer_file_pull').on('value', function(snapshot) {
        state.db.last_customer_file_pull = snapshot.val();
      });

      firebase.database().ref('last_purchase_order_file_pull').on('value', function(snapshot) {
        state.db.last_purchase_order_file_pull = snapshot.val();
      });

      firebase.database().ref('last_po_confirmations_and_eta_requests_triggered').on('value', function(snapshot) {
        state.db.last_po_confirmations_and_eta_requests_triggered = snapshot.val();
      });

      firebase.database().ref('analytics').on('value', function(snapshot) {
        state.db.analytics = snapshot.val();
      });

      firebase.database().ref('shipping_companies').on('value', function(snapshot) {
        state.db.shipping_companies = snapshot.val()
      });      
    }
  },
  actions: {
    loadDatabase: ({ commit }, uid) => commit('loadDatabase', uid),
  },
})
